import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { NewItemComponent } from './components/new-item/new-item.component';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { StorePickerComponent } from './components/store-picker/store-picker.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { CanActivateHasStore } from './route-guard/can-activate-has-store';
import { HelpComponent } from './components/help/help.component';


const routes: Routes = [
	{
		path: ':storeId/register',
		component: CheckoutComponent,
		canActivate: [CanActivateHasStore],
		pathMatch: 'full'
	},
	{
		path: ':storeId/new_item',
		component: NewItemComponent,
		canActivate: [CanActivateHasStore],
		pathMatch: 'full'
	},
	{
		path: 'store_picker',
		component: StorePickerComponent,//CreateStoreComponent,
		pathMatch: 'full'
	},
	{
		path: ':storeId/help',
		component: HelpComponent,
		pathMatch: 'full'
	},
	{
		path: 'help',
		component: HelpComponent,
		pathMatch: 'full'
	},
	
	{
		path: '',
		component: RedirectComponent,
		pathMatch: 'full'
	},
	{
		path: '**',
		redirectTo: ''
	}
];

@NgModule({
	imports: [RouterModule.forRoot(routes)],//, { enableTracing: true })],
	exports: [RouterModule],
	providers: [CanActivateHasStore]
})
export class AppRoutingModule { }
