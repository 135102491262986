import { Component, EventEmitter, OnInit, Output } from '@angular/core';

@Component({
	selector: 'app-price-input',
	templateUrl: './price-input.component.html',
	styleUrls: ['./price-input.component.scss']
})
export class PriceInputComponent implements OnInit {
	public price: string;
	@Output() public priceResult = new EventEmitter<number>();

	constructor() { }

	public ngOnInit(): void {
		this.price = (Math.round((Math.random() * (50 - 1.5) + 1.5) * 100) / 100).toFixed(2);
	}

	public onSaveClick() {
		this.priceResult.emit(+this.price);
	}
}
