<div class="store-picker-container">
	<div class="company-name">
		<h1>
			<img src="/assets/logo line.svg" alt="Play Store Fun Logo" width="33" height="33" style="display:inline;">
			Play Store Fun
		</h1>
	</div>
	<label>
		Enter your store ID:
		<input type="text" #storeID [(ngModel)]="storeId" (keyup.enter)="onSubmitClick()" >
	</label>
	<div *ngIf="showError" class="error">Invalid store ID.</div>
	<div>
		<button (click)="onSubmitClick()" class="button-submit">Access Store</button>	
	</div>
</div>
