import { ChangeDetectorRef, Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NewItemState } from 'src/app/enums/new-item-state';
import { Item } from 'src/models/item';
import { StorageService } from 'src/services/storage.service';

@Component({
	selector: 'app-new-item',
	templateUrl: './new-item.component.html',
	styleUrls: ['./new-item.component.scss']
})
export class NewItemComponent implements OnInit {
	public storeId: string;
	public state: NewItemState;
	public newItemStates = NewItemState;
	public item: Item;

	constructor(
		private route: ActivatedRoute,
		private storageService: StorageService,
		private changeDetector: ChangeDetectorRef,
		private router: Router
	) { }

	public ngOnInit(): void {
		this.storeId = this.route.snapshot.params.storeId;
		this.updateState(NewItemState.Barcode);
		this.initItem();
	}

	public pictureTaken(blob: Blob) {
		this.storageService
			.savePicture(this.storeId, this.item.id, blob)
			.then(fulfilled => {
				this.item.picUrl = `https://storage.googleapis.com/point-of-sale-ccab7.appspot.com/${fulfilled.metadata.fullPath}`;
			});
	}

	private initItem(): void {
		this.item = {
			id: '',
			picUrl: '',
			name: '',
			amount: 0
		} as Item;
	}

	public barcodeRegistered(value) {
		this.item.id = value;
		this.updateState(NewItemState.Picture);
	}

	private updateState(newState: NewItemState) {
		this.state = newState;
		console.log(this.state);
		this.changeDetector.detectChanges();
	}

	public itemSubmitted(value: number) {
		this.item.amount = value;
		this.storageService.setItem(this.storeId, this.item);
		this.updateState(NewItemState.Barcode);
		this.initItem();
	}

	public registerClick() {
		this.router.navigate([this.storeId, 'register']);
	}
}
