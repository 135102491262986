<div class="checkout-container">
	<div class="listing" #listing>
		<div *ngFor="let item of items" class="item-set">
			<img [src]="item.picUrl" />
			<div class="name">{{ item.name }}</div>
			<div class="price lcd">${{ (item.amount).toFixed(2) }}</div>
		</div>
	</div>
	<div class="right-panel">
		<app-barcode (barcodeRegister)="itemScanned($event)"></app-barcode>
		<div class="total-container">
			<span class="label">Total:</span> <span class="total-amount lcd">${{total}}</span>
		</div>
		<button (click)="checkoutClick()" [disabled]="!items || items.length == 0">Checkout</button>
	</div>
</div>
<img src="/assets/newItem.png" class='new-item-link' (click)="newItemClick()">
<img src="/assets/blue_question_mark_icon.svg.png" class="help-link" (click)="onHelpClick()">