import { Injectable } from "@angular/core";
import { ActivatedRouteSnapshot, CanActivate, RouterStateSnapshot, UrlTree } from "@angular/router";
import { Observable } from "rxjs";
import { StorageService } from "src/services/storage.service";

@Injectable()
export class CanActivateHasStore implements CanActivate {
  constructor(private storageService: StorageService) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean|UrlTree>|Promise<boolean|UrlTree>|boolean|UrlTree {
    const storeId = route.params.storeId;
    return this.storageService.hasStore(storeId);
  }
}