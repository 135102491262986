import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { StorageService } from "src/services/storage.service";

@Component({
	selector: 'app-store-picker',
	templateUrl: './store-picker.component.html',
	styleUrls: ['./store-picker.component.scss']
})
export class StorePickerComponent implements OnInit, OnDestroy {
	public storeId: string;
	public showError = false;
	private destroy$ = new Subject();
	@ViewChild('storeID') storeIdElem: ElementRef;
	constructor(private router: Router, private storageService: StorageService) { }

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public ngOnInit(): void {
		setTimeout(() => {
			this.storeIdElem.nativeElement.focus();
		}, 0);
	}

	public onSubmitClick() {
		const storeId = this.storeId.toUpperCase();
		this.storageService.hasStore(storeId)
			.pipe(takeUntil(this.destroy$))
			.subscribe(hasStore => {
					if(hasStore) {
						this.router.navigate([storeId, 'help']);
					}else{
						this.showError = true;
					}
				});
	}
}
