import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { AngularFireModule } from '@angular/fire';
import { AngularFireStorageModule, BUCKET } from '@angular/fire/storage';
import { environment } from '../environments/environment';
import { CameraComponent } from 'src/app/components/camera/camera.component';
import { BarcodeComponent } from 'src/app/components/barcode/barcode.component';
import { CreateStoreComponent } from './components/create-store/create-store.component';
import { NewItemComponent } from './components/new-item/new-item.component';
import { PriceInputComponent } from './components/price-input/price-input.component';
import { FormsModule } from '@angular/forms';
import { CurrencyPipe } from '@angular/common';
import { CheckoutComponent } from './components/checkout/checkout.component';
import { StorePickerComponent } from './components/store-picker/store-picker.component';
import { RedirectComponent } from './components/redirect/redirect.component';
import { HelpComponent } from './components/help/help.component';


@NgModule({
	declarations: [
		AppComponent,
		CameraComponent,
		BarcodeComponent,
		CreateStoreComponent,
		NewItemComponent,
		CheckoutComponent,
		HelpComponent,
		StorePickerComponent,
		RedirectComponent,
		PriceInputComponent
	],
	imports: [
		BrowserModule,
		AngularFireModule.initializeApp(environment.firebase),
		FormsModule,
		AngularFireStorageModule,
		AppRoutingModule
	],
	providers: [
		{ provide: BUCKET, useValue: 'point-of-sale-ccab7.appspot.com' },
		CurrencyPipe
	],
	bootstrap: [AppComponent]
})
export class AppModule { }
