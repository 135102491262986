import { ElementRef, EventEmitter, OnDestroy, Output, ViewChild } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-camera',
	templateUrl: './camera.component.html',
	styleUrls: ['./camera.component.scss']
})
export class CameraComponent implements OnInit, OnDestroy {
	@Output() public pictureTaken = new EventEmitter<Blob>();

	public width = 480;
	public height: number;
	@ViewChild('cameraCanvas', { static: true })
	public canvasElement: ElementRef;
	public get canvas(): HTMLCanvasElement {
		return this.canvasElement.nativeElement;
	}
	@ViewChild('cameraVid', { static: true })
	public videoElement: ElementRef;
	public get video(): HTMLVideoElement {
		return this.videoElement.nativeElement;
	}
	public streaming = false;
	private destroy$ = new Subject();
	public isVideoing = true;
	public localStream: MediaStream;

	constructor() { }
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public ngOnInit() {
		this.videoOn();
		fromEvent(this.video, 'canplay')
			.pipe(takeUntil(this.destroy$))
			.subscribe(() => {
				this.height = this.video.videoHeight / (this.video.videoWidth / this.width);
				this.video.setAttribute('width', this.width + 'px');
				this.video.setAttribute('height', this.height + 'px');
				this.canvas.setAttribute('width', this.width + 'px');
				this.canvas.setAttribute('height', this.height + 'px');
				this.streaming = true;
			});
	}

	private videoOn(): void {
		navigator.mediaDevices.getUserMedia({video: true, audio: false})
		.then((stream: MediaStream) => {
			this.isVideoing = true;
			this.localStream = stream;
			this.video.srcObject = this.localStream;
			this.video.play();
		})
		.catch(err => {
			console.log('video error occured', err);
		});
	}

	private videoOff(): void {
		this.isVideoing = false;
		this.video.pause();
		this.localStream.getTracks()[0].stop();
	}

	public takePhoto() {
		const context = this.canvas.getContext('2d');
		context.drawImage(this.video, 0, 0, this.width, this.height);
		this.videoOff();
		this.canvas.toBlob((blob: Blob) => {
			this.pictureTaken.emit(blob);
		});
	}

	public redoPhoto() {
		this.videoOn();
	}
}
