import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { StorageService } from 'src/services/storage.service';

@Component({
	selector: 'app-create-store',
	templateUrl: './create-store.component.html',
	styleUrls: ['./create-store.component.scss']
})
export class CreateStoreComponent implements OnInit {

	constructor(private storageService: StorageService, private router: Router) { }

	public ngOnInit(): void {
		this.storageService.addStore()
		.then( key => {
			this.router.navigate([key, 'new_item']);
		})
		.catch(error => {
			console.log(error);
			alert('Error creating store.');
		});
	}
}
