import { Injectable } from '@angular/core';
import {
	AngularFirestore, AngularFirestoreCollection, AngularFirestoreDocument,
	DocumentData,
	DocumentSnapshot
} from '@angular/fire/firestore';
import { Observable } from 'rxjs/internal/Observable';
import { filter, map } from 'rxjs/operators';
import { Store } from 'src/models/store';
import { Item } from 'src/models/item';
import { AngularFireStorage, AngularFireUploadTask } from '@angular/fire/storage';
import { v4 as uuidv4 } from 'uuid';


@Injectable({
	providedIn: 'root'
})
export class StorageService {
	constructor(private db: AngularFirestore, private storage: AngularFireStorage) {
	}

	private getStoreCollection(): AngularFirestoreCollection<Store> {
		return this.db.collection<Store>('stores');
	}

	private getItemCollection(storeId: string): AngularFirestoreCollection<Item> {
		return this.getStore(storeId).collection<Item>('items');
	}

	private getItemDoc(storeId: string, itemId: string): AngularFirestoreDocument<Item> {
		return this.getItemCollection(storeId).doc<Item>(itemId);
	}

	private getStore(storeId: string): AngularFirestoreDocument<Store> {
		return this.getStoreCollection().doc<Store>(storeId);
	}

	public hasStore(storeId: string): Observable<boolean> {
		return this.getStore(storeId)
			.get()
			.pipe(
				map(snapshot => !!snapshot.data())
			);
	}

	public addStore(): Promise<string> {
		return new Promise<string>((resolve, reject) => {
			const store = {
				id: this.db.createId(),
				name: 'My Store'
			} as Store;
			this.getStoreCollection()
				.doc(store.id)
				.set(store)
				.then(_ => resolve(store.id))
				.catch(err => reject(err));
		});
	}

	public setItem(storeId: string, item: Item): Promise<void> {
		return this.getItemDoc(storeId, item.id)
			.set(item);
	}

	public getItem(storeId: string, itemId: string): Observable<Item> {
		return this.getItemDoc(storeId, itemId)
			.get()
			.pipe(
				map(snapshot => snapshot.data() as Item),
				filter(snapshot => !!snapshot)
			);
	}

	public savePicture(storeId: string, barcodeId: string, blob: Blob): AngularFireUploadTask {
		const ref = this.storage.ref(`${storeId}/${barcodeId}.png`);
		return ref.put(blob);
	}

}
