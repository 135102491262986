// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
	production: false,
	firebase: {
		apiKey: 'AIzaSyCgZFs1S3DN-3CPQ8I3f8AGlIfZ6aVS21s',
		authDomain: 'point-of-sale-ccab7.firebaseapp.com',
		databaseURL: 'https://point-of-sale-ccab7.firebaseio.com',
		projectId: 'point-of-sale-ccab7',
		storageBucket: 'point-of-sale-ccab7.appspot.com',
		messagingSenderId: '972676418463',
		appId: '1:972676418463:web:fd5d93dbcf98a024fc0baa'
	}
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
