import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Subject } from 'rxjs';
import { Item } from 'src/models/item';
import { StorageService } from 'src/services/storage.service';

@Component({
	selector: 'app-checkout',
	templateUrl: './checkout.component.html',
	styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit, OnDestroy {
	public items: Item[] = [];
	public storeId: string;
	public destroy$ = new Subject();
	private checkoutAudio = new Audio();

	public get total(): string {
		return this.items.reduce((pv, cv) => pv + cv.amount, 0).toFixed(2);
	}

	@ViewChild('listing') private listingElem: ElementRef;

	constructor(private storageService: StorageService, private route: ActivatedRoute, private router: Router) { }

	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public ngOnInit(): void {
		this.storeId = this.route.snapshot.params.storeId;
		this.checkoutAudio.src = '/assets/checkout.mp3';
		this.checkoutAudio.load();
	}

	private scrollListing(): void {
		try {
			this.listingElem.nativeElement.scrollTop = this.listingElem.nativeElement.scrollHeight;
		} catch (err) {console.log(err); }
	}

	public itemScanned(barcode: string) {
		this.storageService
			.getItem(this.storeId, barcode)
			.subscribe(item => {
				item.picUrl = item.picUrl === '' ? '/assets/placeholder.png' : item.picUrl;
				this.items.push(item);
				window.setTimeout(() => this.scrollListing(), 250);
			});
	}

	public onHelpClick() {
		this.router.navigate([this.storeId, 'help']);
	}

	public checkoutClick() {
		this.items = [];
		this.checkoutAudio.play();
	}

	public newItemClick() {
		this.router.navigate([this.storeId, 'new_item']);
	}
}
