import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
	selector: 'app-help',
	templateUrl: './help.component.html',
	styleUrls: ['./help.component.scss']
})
export class HelpComponent implements OnInit {
	public storeId: string;
	constructor(private route: ActivatedRoute, private router: Router) { }

	public ngOnInit(): void {
		this.storeId = this.route.snapshot.params.storeId;
	}

	public onContinueClick() {
		if(!this.storeId) {
			this.router.navigate(['store_picker']);
		} else {
			this.router.navigate([this.storeId, 'register']);
		}
	}
}
