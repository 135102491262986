import { Component } from '@angular/core';
import { StorageService } from 'src/services/storage.service';

@Component({
	selector: 'app-root',
	templateUrl: './app.component.html',
	styleUrls: ['./app.component.scss']
})
export class AppComponent {
	title = 'point-of-sale';
	constructor() {
	}
}
