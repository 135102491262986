import { EventEmitter, OnDestroy, Output } from '@angular/core';
import { Component, OnInit } from '@angular/core';
import { fromEvent, Subject } from 'rxjs';
import { buffer, debounceTime, filter, map, takeUntil } from 'rxjs/operators';

@Component({
	selector: 'app-barcode',
	templateUrl: './barcode.component.html',
	styleUrls: ['./barcode.component.scss']
})
export class BarcodeComponent implements OnInit, OnDestroy {
	@Output() public barcodeRegister = new EventEmitter<string>();
	private destroy$ = new Subject();
	private scannerAudio = new Audio();

	constructor() { }
	public ngOnDestroy(): void {
		this.destroy$.next();
		this.destroy$.complete();
	}

	public ngOnInit() {
		const source = fromEvent(document, 'keypress')
		.pipe(
			takeUntil(this.destroy$)
		);
		source.pipe(
			filter((e: KeyboardEvent) => e.key.length === 1),
			map((e: KeyboardEvent) => e.key),
			buffer(source.pipe(debounceTime(500))),
			map(chars => chars.reduce((pv, cv) => pv + cv, ''))
		)
		.subscribe(val => {
			this.scannerAudio.play();
			this.barcodeRegister.emit(val);
		} );
		this.scannerAudio.src = '/assets/scanner.mp3';
		this.scannerAudio.load();
	}
}
