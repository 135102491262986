import { Component, OnInit } from '@angular/core';

@Component({
	selector: 'app-redirect',
	template: ''
})
export class RedirectComponent implements OnInit {
	constructor() { }

	public ngOnInit(): void {
		window.location.href = "https://www.playstorefun.com";
	}
}
